import { getAuth, getIdTokenResult, User } from "firebase/auth";
import {
  addDoc,
  collection,
  type CollectionReference,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { firebaseApp } from "./firebaseConfig";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { AuthClaim } from "./components/admin/PermissionsManager";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51NZgfZFBIwpy1XnfOTt5RoMTKk8wKpZePnqMij7Yueakk7P2mXK7a1LP9T6onDAgGfLCztGlHOgP0WdA3GfStI1n00PprjApR0",
    );
  }
  return stripePromise;
};

// this has the side effect of creating the stripe user account and linking it locally if it doesn't exist
// TODO: make sure user email is verified if only email auth
// We don't want to allow users to create a stripe account without verifying their email
export async function createPortalLink() {
  const result = await httpsCallable(
    getFunctions(firebaseApp),
    "ext-firestore-stripe-payments-createPortalLink",
  )({
    returnUrl: "https://techdisc.com",
  });
  return result;
}

// this has the side effect of creating the stripe user account and linking it locally if it doesn't exist
// TODO: make sure user email is verified if only email auth
// We don't want to allow users to create a stripe account without verifying their email
export async function createCheckoutSession(uid: string, priceId: string = STORE_PRICE_YR_ID) {
  const store = getFirestore(firebaseApp);
  const path = "/stripe/data/customers/" + uid + "/checkout_sessions";
  const checkouts: CollectionReference = collection(store, path);
  const checkoutSessionRef = await addDoc(checkouts, {
    price: priceId,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });

  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(checkoutSessionRef, async (snap) => {
    // @ts-ignore
    const { sessionId } = snap.data();
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await initializeStripe();
      stripe?.redirectToCheckout({ sessionId });
    }
  });
}

export enum PremiumFeature {
  NONE = "none", // this is the default value
  STORE = "store_features",
  PLAY = "play_features",
  PRO = "pro_features",
}

const STORE_PRICE_YR_ID = "price_1O5XfRFBIwpy1Xnf4pWGTAZ9";
const STORE_PRICE_MO_ID = "price_1O5XSbFBIwpy1XnfDbKCaXfz";

const PRO_PRICE_YR_ID = "price_1O5XbnFBIwpy1Xnf6W1BUHub";
const PRO_PRICE_MO_ID = "price_1O5EumFBIwpy1XnfFeXCCSNy";

export async function hasStoreFeatures(user: User): Promise<boolean> {
  const decodedToken = await getIdTokenResult(user, true);
  return decodedToken?.claims?.stripeRole == "store_features";
}

async function fetchTokenAndSetClaims(
  user: User,
  setClaims?: (value: { [p: string]: any }) => void,
  setPremiumStatus?: (value: PremiumFeature) => void,
) {
  const analytics = getAnalytics(firebaseApp);
  const decodedToken = await getIdTokenResult(user, true);
  // only set if it matches an enum value
  if (decodedToken) {
    setClaims?.(decodedToken.claims);
  }

  const { stripeRole, revenueCatEntitlements } = decodedToken?.claims as {
    stripeRole?: PremiumFeature;
    revenueCatEntitlements?: PremiumFeature[];
  };

  if (stripeRole) {
    if ([PremiumFeature.STORE, PremiumFeature.PRO].includes(stripeRole)) {
      setPremiumStatus?.(stripeRole);
    } else {
      const message = "invalid premium status: " + stripeRole;
      logEvent(analytics, message);
      console.error(message);
    }
  } else if (revenueCatEntitlements?.length) {
    if (revenueCatEntitlements.some((entitlement) => entitlement === PremiumFeature.PRO)) {
      setPremiumStatus?.(PremiumFeature.PRO);
    } else {
      const message = "invalid premium status: " + revenueCatEntitlements.join(", ");
      logEvent(analytics, message);
      console.error(message);
    }
  }
}

export function usePremiumStatus(user: User | undefined | null): PremiumFeature | undefined {
  const [premiumStatus, setPremiumStatus] = useState<PremiumFeature>();

  useEffect(() => {
    if (user) {
      fetchTokenAndSetClaims(user, undefined, setPremiumStatus);
    }
  }, [user, user?.refreshToken]);

  return premiumStatus;
}

export function useAuthClaims(): AuthClaim[] | undefined {
  const [user, userLoading, userError] = useAuthState(getAuth(firebaseApp));
  const [claims, setClaims] = useState<{ [key: string]: any } | undefined>();

  useEffect(() => {
    if (user) {
      fetchTokenAndSetClaims(user, setClaims, undefined);
    }
  }, [user, user?.refreshToken]);

  if (claims == undefined) {
    return undefined;
  }

  // the value for each claim must be truthy.  We use true for now.
  // later a given claim may have a value like "read" or "write"
  return Object.values(AuthClaim).filter((claim) => claims?.[claim]);
}

export const MANAGE_SUBSCRIPTION_URL = "https://billing.stripe.com/p/login/6oEg0a0379EAgiQ8ww";
