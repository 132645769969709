import { SVGProps } from "react";
import { AnimatePresence, motion, type MotionProps } from "framer-motion";

export type BatteryLevelProps = SVGProps<SVGSVGElement> &
  MotionProps & {
    batteryLevel: number;
    isCharging: boolean;
    fillColor: string;
    strokeColor: string;
  };

const BatteryLevel = (props: BatteryLevelProps) => {
  const { batteryLevel, isCharging, fillColor, strokeColor, ...rest } = props;

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <motion.rect width={18} height={12} x={1} y={6} stroke={strokeColor} strokeWidth={2} rx={3} />
      <motion.rect width={3} height={6} x={19} y={9} fill={strokeColor} rx={2} />

      <AnimatePresence>
        {isCharging ? (
          <>
            <motion.rect
              initial={{ width: 0 }}
              animate={{
                width: [0, 14],
                transition: {
                  duration: 3,
                  repeat: Infinity,
                  repeatDelay: 1,
                },
              }}
              exit={{ width: 0 }}
              width={13}
              height={8}
              x={3}
              y={8}
              fill={fillColor}
              rx={1}
            />
          </>
        ) : (
          <>
            {batteryLevel >= 10 && (
              <motion.rect
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                width={4}
                height={8}
                x={3}
                y={8}
                fill={fillColor}
                rx={1}
              />
            )}
            {batteryLevel >= 30 && (
              <motion.rect
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                width={4}
                height={8}
                x={8}
                y={8}
                fill={fillColor}
                rx={1}
              />
            )}
            {batteryLevel >= 60 && (
              <motion.rect
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                width={4}
                height={8}
                x={13}
                y={8}
                fill={fillColor}
                rx={1}
              />
            )}
          </>
        )}
      </AnimatePresence>
      {/* <path fill="currentColor" d="M5.601 37a6 6 0 0 1 6-6h20v38h-20a6 6 0 0 1-6-6V37Z" /> */}
    </motion.svg>
  );
};
export default BatteryLevel;
